import React, { FC, PropsWithChildren, useMemo } from "react";

import {
  DefinitionForType,
  Resource,
  ResourceType,
} from "../../../types/serializers";

import Store, { StoreOpts } from "./Store";

export const StoreContext = React.createContext<Store>({} as Store);

export interface JsonAPIError {
  code?: string;
  detail?: string;
  source?: { parameter: string };
  status?: number;
  title: string;
}
export type JsonAPIErrors = JsonAPIError[];

export interface JsonAPIData<T extends ResourceType | unknown = unknown> {
  data?: DefinitionForType<T>;
  errors?: JsonAPIErrors;
  included?: Resource[];
}

interface StoreContextProviderProps extends PropsWithChildren {
  seed: { [root: string]: JsonAPIData };
  storeOpts?: StoreOpts;
}

const models = {
  [ResourceType.PillarPageBlock]: { fetch: false },
};

const StoreContextProvider: FC<StoreContextProviderProps> = ({
  children,
  seed,
  storeOpts,
}) => {
  const mergedStoreOpts = { ...(storeOpts ?? {}), models };
  const store = useMemo(() => new Store(seed, mergedStoreOpts), [seed]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreContextProvider;
