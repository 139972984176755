import { AppendID } from "./helpers";

export type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>
  | null
  | undefined;
export type JsonObject = { [x: string]: JSONValue };

export interface UnknownAttributes {
  [key: string]: JSONValue | null | undefined;
}

export interface EmptyResource<T extends ResourceType | unknown = unknown> {
  id: string;
  type: T extends ResourceType ? T : ResourceType;
}

export interface HasManyData<T extends ResourceType | unknown = unknown> {
  data?: EmptyResource<T>[];
}
export interface HasOneData<T extends ResourceType | unknown = unknown> {
  data?: EmptyResource<T>;
}
export type RelationData = {
  [key: string]: HasOneData | HasManyData;
};

export type Resource<T extends ResourceType | unknown = unknown> =
  EmptyResource<T> & {
    attributes?: UnknownAttributes;
    relationships?: RelationData;
  };

export type ResourceDefinition<
  T extends ResourceType,
  A = void,
  R = void
> = EmptyResource<T> & {
  attributes: A extends void ? undefined : A;
  relationships: R extends void ? undefined : R;
};

export enum ResourceType {
  Action = "actions",
  ApplicationSetting = "application_settings",
  Blog = "blogs",
  BlogAuthor = "blog_authors",
  ClassifierResult = "classifier_results",
  Collection = "collections",
  EmbeddedBlog = "embedded_blogs",
  EmbeddedFaq = "embedded_faqs",
  ExpertiseDomain = "expertise_domains",
  Feedback = "feedbacks",
  GlossaryItem = "glossary_items",
  ChatConversation = "chat_conversations",
  ChatMessage = "chat_messages",
  ChatParticipation = "chat_participations",
  Keyword = "keywords",
  MediaObject = "media_objects",
  NewsletterSubscription = "newsletter_subscriptions",
  PillarPage = "pillar_pages",
  PillarPageBlock = "pillar_pages_blocks",
  Redirect = "redirects",
  Status = "statuses",
  TestResource = "test_resources",
  User = "users",
}

export interface ResourceDefinitions {
  [ResourceType.Action]: ActionResource;
  [ResourceType.ApplicationSetting]: ApplicationSettingResource;
  [ResourceType.Blog]: BlogResource;
  [ResourceType.BlogAuthor]: BlogAuthorResource;
  [ResourceType.ChatConversation]: ChatConversationResource;
  [ResourceType.ChatMessage]: ChatMessageResource;
  [ResourceType.ChatParticipation]: ChatParticipationResource;
  [ResourceType.ClassifierResult]: ClassifierResultResource;
  [ResourceType.Collection]: CollectionResource;
  [ResourceType.EmbeddedBlog]: EmbeddedBlogResource;
  [ResourceType.EmbeddedFaq]: EmbeddedFaqResource;
  [ResourceType.ExpertiseDomain]: ExpertiseDomainResource;
  [ResourceType.Feedback]: FeedbackResource;
  [ResourceType.GlossaryItem]: GlossaryItemResource;
  [ResourceType.Keyword]: KeywordResource;
  [ResourceType.MediaObject]: MediaObjectResource;
  [ResourceType.NewsletterSubscription]: NewsletterSubscriptionResource;
  [ResourceType.PillarPage]: PillarPageResource;
  [ResourceType.PillarPageBlock]: PillarPageBlockResource;
  [ResourceType.Redirect]: RedirectResource;
  [ResourceType.Status]: StatusResource;
  [ResourceType.TestResource]: TestResourceResource;
  [ResourceType.User]: UserResource;
}

export type DefinitionForType<
  T extends ResourceType | typeof ResourceType | unknown
> = T extends ResourceType ? ResourceDefinitions[T] : Resource;

export type ActionAttributes = {
  actionType: string;
  name: string;
  started: boolean;
};
export type ActionResource = ResourceDefinition<
  ResourceType.Action,
  ActionAttributes,
  undefined
>;

export type ApplicationSettingAttributes = {
  humanValue: string;
  key: string;
  valueExplanation: string;
  valueType: string;
};
export type ApplicationSettingResource = ResourceDefinition<
  ResourceType.ApplicationSetting,
  ApplicationSettingAttributes
>;

export type BlogAuthorAttributes = {
  bio: string;
  blogsCount: number;
  createdAt: string;
  name: string;
  updatedAt: string;
};
export type BlogAuthorRelationships = {
  profilePhoto: HasOneData<ResourceType.MediaObject>;
};
export type BlogAuthorResource = ResourceDefinition<
  ResourceType.BlogAuthor,
  BlogAuthorAttributes,
  BlogAuthorRelationships
>;

export type BlogAttributes = {
  categoryList: string[];
  createdAt: string;
  intro: string;
  markdown: string;
  pageViews?: number;
  previewText: string;
  published: boolean;
  publishedAt?: string;
  revisionInterval: string;
  seoDescription: string;
  seoKeyphrase: string;
  seoTitle: string;
  slug: string;
  title: string;
  updatedAt: string;
};
export type BlogRelationships = {
  blogAuthor: HasOneData<ResourceType.BlogAuthor>;
  coverImage: HasOneData<ResourceType.MediaObject>;
  pillarPage: HasOneData<ResourceType.PillarPage>;
};
export type BlogResource = ResourceDefinition<
  ResourceType.Blog,
  BlogAttributes,
  BlogRelationships
>;

export type EmbeddedBlogRelationships = {
  blog: HasOneData<ResourceType.Blog>;
  target: HasOneData<ResourceType.PillarPageBlock>;
};
export type EmbeddedBlogResource = ResourceDefinition<
  ResourceType.EmbeddedBlog,
  undefined,
  EmbeddedBlogRelationships
>;

export type EmbeddedFaqAttributes = {
  answer: string;
  question: string;
};
export type EmbeddedFaqResource = ResourceDefinition<
  ResourceType.EmbeddedFaq,
  EmbeddedFaqAttributes
>;

export interface Prediction extends JsonObject {
  id: string;
  score: number;
}

export type ClassifierResultAttributes = {
  prediction: Prediction[];
  text: string;
};
export type ClassifierResultResource = ResourceDefinition<
  ResourceType.ClassifierResult,
  ClassifierResultAttributes
>;

export type CollectionObjectKeys<T extends ResourceType | unknown> =
  T extends ResourceType
    ?
        | "id"
        | keyof DefinitionForType<T>["attributes"]
        | keyof AppendID<DefinitionForType<T>["relationships"]>
    : "id";

export type CollectionFilters<T extends ResourceType | unknown = unknown> =
  Partial<Record<CollectionObjectKeys<T>, string[]>>;
export enum CollectionOrderDirection {
  Asc = "asc",
  Desc = "desc",
}
export type CollectionOrderings<T extends ResourceType | unknown = unknown> =
  Partial<Record<CollectionObjectKeys<T>, CollectionOrderDirection>>;
export type CollectionAttributes<T extends ResourceType | unknown = unknown> = {
  itemType: T extends ResourceType ? T : ResourceType;
  page: number;
  pageSize: number;
  query?: string;
  totalCount: number;
};
export type CollectionRelationships<
  T extends ResourceType | unknown = unknown
> = {
  items: HasManyData<T>;
};
export type CollectionResource<T extends ResourceType | unknown = unknown> =
  ResourceDefinition<
    ResourceType.Collection,
    CollectionAttributes<T>,
    CollectionRelationships<T>
  >;

export type ExpertiseDomainAttributes = {
  name: string;
};
export type ExpertiseDomainResource = ResourceDefinition<
  ResourceType.ExpertiseDomain,
  ExpertiseDomainAttributes
>;

export type GlossaryItemAttributes = {
  active: boolean;
  content: string;
  createdAt: string;
  term: string;
  updatedAt: string;
};

export type GlossaryItemResource = ResourceDefinition<
  ResourceType.GlossaryItem,
  GlossaryItemAttributes
>;

export enum ChatConversationStatus {
  Active = "active_conversation",
  HelpedQuery = "helped_query",
  PendingAcceptance = "pending_acceptance",
  Closed = "closed",
}

export enum ChatClosedReason {
  Cancelled = "cancelled",
  Finished = "finished",
  Inactive = "inactive",
  NotAccepted = "not_accepted",
}

export enum Compliment {
  QuickResponse = "quick_response",
  WellHelped = "well_helped",
  PleasantConversation = "pleasant_conversation",
  Understanding = "understanding",
}

export type ChatConversationRelationships = {
  activeFeedback: HasOneData<ResourceType.Feedback>;
  chatMessages: HasManyData<ResourceType.ChatMessage>;
  chatParticipations: HasManyData<ResourceType.ChatParticipation>;
  expertiseDomain: HasOneData<ResourceType.ExpertiseDomain>;
  user: HasOneData<ResourceType.User>;
  volunteer: HasOneData<ResourceType.User>;
};
export type ChatConversationAttributes = {
  accepted?: never;
  chatType: ChatType;
  closedReason?: ChatClosedReason;
  createdAt: string;
  declined?: never;
  followed?: never;
  initialMessage: string;
  journeyId?: string;
  lastMessageAt: string;
  referer?: string;
  relevant?: never;
  status: ChatConversationStatus;
  toReview?: never;
  typingUserIds: string[];
  waitingForGuide: boolean;
  wizardId?: string;
};

export type ChatConversationResource = ResourceDefinition<
  ResourceType.ChatConversation,
  ChatConversationAttributes,
  ChatConversationRelationships
>;

export enum ChatType {
  AI = "ai_chat",
  VolunteerChat = "volunteer_chat",
}

export type ChatMessageRelationships = {
  chatConversation: HasOneData<ResourceType.ChatConversation>;
  feedback?: HasOneData<ResourceType.Feedback>;
  user: HasOneData<ResourceType.User>;
};

export enum ChatMessageType {
  ErrorMessage = "error_message",
  FeedbackQuery = "feedback_query",
  GptMessage = "gpt_message",
  HelpedQuery = "helped_query",
  JourneyContent = "journey_content",
  QuestionReceived = "question_received",
  RecommendedBlog = "recommended_blog",
  SendToVolunteer = "send_to_volunteer",
  SystemMessage = "system_message",
  UserMessage = "user_message",
}

export type ChatMessageAttributes = {
  content: string;
  createdAt: string;
  messageType: ChatMessageType;
  senderType: string;
};

export type ChatMessageResource = ResourceDefinition<
  ResourceType.ChatMessage,
  ChatMessageAttributes,
  ChatMessageRelationships
>;

export enum UserType {
  User = "user",
  AI = "ai",
  Volunteer = "volunteer",
  Supervisor = "supervisor",
}

export type ChatParticipationAttributes = {
  avatarUrl?: string;
  createdAt: string;
  firstname?: string;
  participantType: UserType;
  readAt: string;
};

export type ChatParticipationRelationships = {
  chatConversation: HasOneData<ResourceType.ChatConversation>;
  user: HasOneData<ResourceType.User>;
};

export type ChatParticipationResource = ResourceDefinition<
  ResourceType.ChatParticipation,
  ChatParticipationAttributes,
  ChatParticipationRelationships
>;

export type FeedbackAttributes = {
  compliment: Compliment;
  createdAt: string;
  feedback: string;
  helpedQuery: boolean;
  updatedAt: string;
  userType: UserType;
  volunteerId?: never;
};

export type FeedbackRelationships = {
  chatConversation: HasOneData<ResourceType.ChatConversation>;
  user: HasOneData<ResourceType.User>;
};

export type FeedbackResource = ResourceDefinition<
  ResourceType.Feedback,
  FeedbackAttributes,
  FeedbackRelationships
>;

export type KeywordAttributes = {
  relevance?: number;
  text?: string;
};

export type KeywordResource = ResourceDefinition<
  ResourceType.Keyword,
  KeywordAttributes
>;

export type MediaObjectAttributes = {
  alt: string;
  avatarUrl: string;
  baseEncoded?: string;
  caption: string;
  coverHeight: number;
  coverUrl: string;
  coverWidth: number;
  createdAt: string;
  file: string;
  filename: string;
  thumbHeight: number;
  thumbUrl: string;
  thumbWidth: number;
  updatedAt: string;
  webHeight: number;
  webUrl: string;
  webWidth: number;
};
export type MediaObjectResource = ResourceDefinition<
  ResourceType.MediaObject,
  MediaObjectAttributes
>;

export type NewsletterSubscriptionAttributes = {
  email: string;
  emailSubscription: boolean;
  firstname: string;
};
export type NewsletterSubscriptionResource = ResourceDefinition<
  ResourceType.NewsletterSubscription,
  NewsletterSubscriptionAttributes
>;

export type PillarPageAttributes = {
  body: string;
  categoryList: string[];
  published: boolean;
  publishedAt?: string;
  seoDescription: string;
  seoTitle: string;
  slug: string;
  title: string;
  updatedAt: string;
};

export type PillarPageRelationships = {
  coverImage: HasOneData<ResourceType.MediaObject>;
  pillarPageBlocks: HasManyData<ResourceType.PillarPageBlock>;
};

export type PillarPageResource = ResourceDefinition<
  ResourceType.PillarPage,
  PillarPageAttributes,
  PillarPageRelationships
>;

export type PillarPageBlockAttributes = {
  body: string;
  faqHeading?: string;
  highlightBody?: string;
  highlightTitle?: string;
  position: number;
  title: string;
};

export type PillarPageBlockRelationships = {
  embeddedBlogs: HasManyData<ResourceType.EmbeddedBlog>;
  embeddedFaqs: HasManyData<ResourceType.EmbeddedFaq>;
};

export type PillarPageBlockResource = ResourceDefinition<
  ResourceType.PillarPageBlock,
  PillarPageBlockAttributes,
  PillarPageBlockRelationships
>;

export type RedirectAttributes = {
  from: string;
  lastUsedAt?: string;
  to: string;
  usages: number;
};

export type RedirectResource = ResourceDefinition<
  ResourceType.Redirect,
  RedirectAttributes
>;

export type StatusAttributes = {
  name: string;
  ortecId: number;
  ortecName: string;
  userStatusesCount: number;
};

export type StatusResource = ResourceDefinition<
  ResourceType.Status,
  StatusAttributes
>;

export type TestResourceAttributes = {
  body?: string;
  check?: boolean;
  date?: string;
  description?: string;
  title: string;
};

export type TestResourceRelationships = {
  otherTestResource: HasOneData<ResourceType.TestResource>;
  testResource: HasOneData<ResourceType.TestResource>;
  testResources: HasManyData<ResourceType.TestResource>;
};

export type TestResourceResource = ResourceDefinition<
  ResourceType.TestResource,
  TestResourceAttributes,
  TestResourceRelationships
>;

export type UserAttributes = {
  admin: boolean;
  availabilities?: Record<string, boolean>;
  avatarUrl: string;
  birthdate?: string;
  codeOfConductApprovedAt?: string;
  createdAt: string;
  email?: string;
  emailNotifications: boolean;
  expertiseDomainNames?: string[];
  firstname?: string;
  guideUnsubscribed?: boolean;
  id: string;
  isBlocked: boolean;
  isGuide: boolean;
  isSupervisor: boolean;
  lastname?: string;
  notAvailableUntilOn?: string;
  notepad?: string;
  stoppedAsGuideOn?: string;
  updatedAt: string;
};
export type UserRelationships = {
  newsletterSubscription: HasOneData<ResourceType.NewsletterSubscription>;
};
export type UserResource = ResourceDefinition<
  ResourceType.User,
  UserAttributes,
  UserRelationships
>;
